@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700');
/*
font-family: 'Source Sans Pro', sans-serif;
*/

@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=e556f210-a32f-4153-bf31-273bf4ed5155");
    @font-face{
        font-family:"Proxima Nova W05 Light";
        src:url("fonts/c48b9e64-9616-4651-8136-26dcc90b518f.eot?#iefix");
        src:url("fonts/c48b9e64-9616-4651-8136-26dcc90b518f.eot?#iefix") format("eot"),url("fonts/27f5855e-1f73-42cc-a083-cb0f319ea42a.woff2") format("woff2"),url("fonts/f44944f0-b92e-4152-9ea1-eb41de241416.woff") format("woff"),url("fonts/b3ca6462-e5b3-4cfd-9c86-69bd1444273f.ttf") format("truetype");
    }
    @font-face{
        font-family:"Proxima Nova W05 Light Italic";
        src:url("fonts/75ea72d5-31b5-4fa1-8aed-f74e5bfed7cc.eot?#iefix");
        src:url("fonts/75ea72d5-31b5-4fa1-8aed-f74e5bfed7cc.eot?#iefix") format("eot"),url("fonts/e6045512-9eaa-432c-9041-d40c5e9fa285.woff2") format("woff2"),url("fonts/d6e61fb0-be4b-4b61-8cd7-67b718ab6b9c.woff") format("woff"),url("fonts/8bec7eb5-df72-45c7-bbc5-b5d41afb2883.ttf") format("truetype");
    }
    @font-face{
        font-family:"Proxima Nova W05 Regular";
        src:url("fonts/3bc45708-b148-4161-9d38-fa10e8cd8ed7.eot?#iefix");
        src:url("fonts/3bc45708-b148-4161-9d38-fa10e8cd8ed7.eot?#iefix") format("eot"),url("fonts/33ca2000-af7b-4444-97cd-6392ab2e24b2.woff2") format("woff2"),url("fonts/1c003cc6-0f8f-4c82-adde-a5a026b56b50.woff") format("woff"),url("fonts/96dc6d98-013d-4a94-9c09-8476abc0abc6.ttf") format("truetype");
    }
    @font-face{
        font-family:"Proxima Nova W05 Medium";
        src:url("fonts/e49a997f-7512-4cb2-b647-589aaacffab5.eot?#iefix");
        src:url("fonts/e49a997f-7512-4cb2-b647-589aaacffab5.eot?#iefix") format("eot"),url("fonts/fefa128d-8eb9-48e9-adf7-568b92a2e991.woff2") format("woff2"),url("fonts/8c025621-11f1-4af4-8dfa-69eb993b51b9.woff") format("woff"),url("fonts/7b58123f-5968-4e40-a0e0-6568b05ed816.ttf") format("truetype");
    }
    @font-face{
        font-family:"Proxima Nova W05 Semibold";
        src:url("fonts/f2c17c4e-0df4-4227-9b81-f867288bff41.eot?#iefix");
        src:url("fonts/f2c17c4e-0df4-4227-9b81-f867288bff41.eot?#iefix") format("eot"),url("fonts/3ac241c9-a224-4e42-bfe2-f79949b0b0d1.woff2") format("woff2"),url("fonts/7f01cc93-cfa0-46c2-b30a-82f618cbd876.woff") format("woff"),url("fonts/d612d3cc-3988-4bf3-bc8c-55d4a6c82170.ttf") format("truetype");
    }

/* 
font-family:'Proxima Nova W05 Regular';
font-family:'Proxima Nova W05 Light';
font-family:'Proxima Nova W05 Light Italic';
font-family:'Proxima Nova W05 Medium';
font-family:'Proxima Nova W05 Semibold';
*/

/* ------------------- 
ICOMOON
---------------------- */

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoonv2.eot?9all2k');
    src:  url('fonts/icomoonv2.eot?9all2k#iefix') format('embedded-opentype'),
      url('fonts/icomoonv2.ttf?9all2k') format('truetype'),
      url('fonts/icomoonv2.woff?9all2k') format('woff'),
      url('fonts/icomoonv2.svg?9all2k#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-jl-logo:before {
    content: "\e901";
  }
  .icon-small-arrow:before {
    content: "\e900";
  }
  

/* ------------------- 
VARIABLES
---------------------- */

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1300px,
  xxlarge: 1440px,
);

// breakpoints function  Use: breakpoints(name); 
@function breakpoints($name) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);  
  }
   
  @warn "The key #{$name} is not in the map ’$breakpoints’";
  @return null;
};

// JL Variables
$short-transition: .6s ease-in-out;
$results-img-height: 165px;
$scale-transition-buttons: .35s ease-in-out;

// JL Mixins
@mixin animationNoFlicker {
    -webkit-backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
}

.no-js {
    visibility: hidden;
    opacity: 0;
   }
   
.js {
    visibility: visible;
    opacity: .5;
}



/* ------------------- 
MAIN
---------------------- */

.jl-app {
    float: left;
    width: 100%;
    font-family: 'Proxima Nova W05 Regular', sans-serif;
}

.jl-nav-wrap {
    color: white;
}

.jl-nav-menu {
    padding: 12px;
    font-size: 18px;
}

.jl-nav-menu li a {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: white;
    /* opacity: .85; */
    position: relative;
}

// overwrites for foundation default
.jl-header-wrap .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
    right: 10px;
	margin-top: -3px;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 6px solid #ffffff;
}

.jl-header-wrap.subpage .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
    border-top: 6px solid #1e1e1e;
}

.jl-nav-wrap .dropdown.menu.align-right .is-dropdown-submenu.first-sub
{
    top: 90%;
}

.jl-nav-menu li a.active {
    /* opacity: 1; */
}

/* .jl-nav-menu li a.active:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: white;
    position: absolute;
    left: 3px;
	top: 17px;
} */

.jl-nav-menu li a:hover {
    cursor: pointer;
}

.jl-nav-dropdown {
    background-color: rgba(34, 34, 34, .6);
    border: none;
    // display: block !important;
    min-width: 210px; /* overwrites foundation default 200 */
}

.jl-nav-wrap .jl-nav-dropdown li a {
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: 'Proxima Nova W05 Light';
    width: 100%;
}

.jl-nav-wrap .jl-nav-dropdown li:first-child a {
    padding-top: 13px;
}

.jl-nav-wrap .jl-nav-dropdown li:last-child a {
    padding-bottom: 13px;
}

.jl-nav-wrap .jl-nav-menu .is-active > a {
    color: inherit;
}

.jl-logo {
    float: left;
	font-size: 35px;
	color: white;
    position: absolute;
    z-index: 1;
	top: 13px;
    left: 23px;
    opacity: .9;
}

.jl-logo:hover, .jl-logo:focus {
    color: white;
}

.jl-header-wrap {
    position: relative;
    height: 640px;
    background: url("../images/bg_main.jpg") no-repeat center center;
    background-size: cover;
}

.header-blue-tint {
    content: "";
    position: absolute;
	// background: #183f63;
    // opacity: .6;
    background: #1d4267;
	opacity: .65;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.header-color-tint {
    // background: #1d4267;
    opacity: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#51a7d3+0,72afcd+33,6baa52+100&0.8+0,0.85+25,0.9+50,0.85+75,0.8+100 */
    background: -moz-linear-gradient(left, rgba(81,167,211,0.8) 0%, rgba(106,173,206,0.85) 25%, rgba(114,175,205,0.87) 33%, rgba(112,174,174,0.9) 50%, rgba(110,172,128,0.85) 75%, rgba(107,170,82,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(81,167,211,0.8) 0%,rgba(106,173,206,0.85) 25%,rgba(114,175,205,0.87) 33%,rgba(112,174,174,0.9) 50%,rgba(110,172,128,0.85) 75%,rgba(107,170,82,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(81,167,211,0.8) 0%,rgba(106,173,206,0.85) 25%,rgba(114,175,205,0.87) 33%,rgba(112,174,174,0.9) 50%,rgba(110,172,128,0.85) 75%,rgba(107,170,82,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc51a7d3', endColorstr='#cc6baa52',GradientType=1 ); /* IE6-9 */
}

.splash-text-wrap {
    position: relative;
    color: white;
    max-width: 730px;
    margin: 0 auto;
    text-align: center;
    margin-top: 60px;
}

.splash-name {
    font-family: 'Proxima Nova W05 Medium';
    font-size: 48px;
}

.splash-sentence {
    font-family: 'Proxima Nova W05 Light';
    font-size: 34px;
    line-height: 1.15em;
    margin-top: 25px;
    margin-bottom: 45px;
}

.see-portfolio-btn {
    font-family: 'Source Sans Pro';
    font-size: 25px;
    display: inline-block;
    color: white;
    border: 2px solid white;
    padding: 13px 19px;
    transition: $scale-transition-buttons;
    @include animationNoFlicker;
    transform: scale(0.95);
}

.subpage-hdr-color-bar {
    display: none;
}

/* ------------------- 
SUBPAGE HEADER
---------------------- */

.see-portfolio-btn:hover {
    color: inherit;
    transform: scale(1);
    // background-color: rgba(255, 255, 255, .08);
}

.see-portfolio-btn:focus {
    color: inherit;
    outline: none;
}

.jl-header-wrap.subpage {
    background: none;
    height: auto;
}

.jl-header-wrap.subpage .jl-logo {
	color: #383838;
}

.jl-header-wrap.subpage .header-blue-tint,
.jl-header-wrap.subpage .splash-text-wrap {
    display: none;
}

.jl-header-wrap.subpage .jl-nav-wrap {
    background-color: white;
}

.jl-header-wrap.subpage .jl-nav-menu li a {
    color: #1e1e1e;
}

.jl-header-wrap.subpage .jl-nav-menu .jl-nav-dropdown li a {
    color: #4a4a4a;
}

.jl-header-wrap.subpage .jl-nav-dropdown {
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e0e0e0;
}

.jl-header-wrap.subpage .subpage-hdr-color-bar {
    display: block;
    height: 4px;
    background-image: linear-gradient(to right, #4580c3, #e3bc7e, #5f8f4c);
    opacity: 0.8;
}


/* ------------------- 
TRANSITIONS
---------------------- */

.fade-enter {
    opacity: 0;
    transition: all .25s;
}

.fade-enter.fade-enter-active {
    opacity: 1;
}

.fade-exit {
    opacity: 1;
    transition: all .25s;
}

.fade-exit.fade-exit-active {
    opacity: 0;
}




/* ------------------- 
ELEMENTS
---------------------- */

.heading1 {
    font-family: 'Proxima Nova W05 Medium';
    font-size: 26px;
    color: #656565;
    text-align: center;
    padding-top: 50px;
    margin-bottom: 15px;
}

.heading1-wrap {
    width: 100%;
}

.short-underline {
    width: 75px;
    height: 5px;
    background-color: #d6d6d6;
    display: block;
    margin: auto;
    margin-bottom: 45px;
}


/* ------------------- 
HOME PAGE
---------------------- */

.jl-home-wrap {
    background-color: white;
    margin-bottom: 90px;
}

.featured-work-wrap {
    margin: 55px 10% 90px 10%;
}

.featured-work-spacing-wrap {
    width: 420px;
	margin: 0 auto;
}

.featured-wrk-blk {

}

.featured-wrk-img {
    height: 230px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 auto;
}

.featured-wrk-heading {
    font-family: 'Proxima Nova W05 Medium';
    color: #656565; /* TO DO SASS VARIABLES */
    font-size: 20px;
    padding: 28px 0 10px 0;
}

.featured-wrk-text {
    font-family: 'Proxima Nova W05 Light';
    font-size: 16px;
    color: #575757;
    width: 400px;
    line-height: 1.5em;
}

.featured-wrk-link, .work-together-link {
    font-family: 'Proxima Nova W05 Semibold';
    color: #3368af;
    border: 2px solid #3368af;
    padding: 6px 8px;
}

.featured-wrk-link {
    display: inline-block;
    margin-top: 17px;
    position: relative;
    padding-right: 33px;
    transition: $scale-transition-buttons;
    @include animationNoFlicker;
}

.featured-wrk-link:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.small-arrow {
    position: absolute;
    right: 7px;
    top: 10px;
}


/* ------------------- 
PORTFOLIO
---------------------- */

.portfolio-wrap, .about-wrap {
    background-color: white;
    margin: 0 10%;
    margin-bottom: 80px;
}

.portfolio-project-wrap {
    margin-bottom: 100px;
}

.portfolio-heading-wrap {
    // border-bottom: 2px solid #d8d8d8;
	// padding-bottom: 9px;
}

.port-number {
    float: left;
    font-family:'Proxima Nova W05 Light';
    font-size: 40px;
    line-height: 40px;
    color: #8a8a8a;
    margin-bottom: 5px;
    position: relative;
}

.port-number:before {
    content:">";
    font-size: 34px;
    font-family:'Proxima Nova W05 Regular';
    position: absolute;
    left: -33px;
}

.port-headline-wrap {
    color: #656565;
    clear: both;
}

.port-headline {
    font-family:'Proxima Nova W05 Medium';
    font-size: 22px;
    float: left;
}

.port-year {
    font-family:'Proxima Nova W05 Light';
    font-size: 22px;
    float: right;
    color: #828282;
    padding-top: 3px;
}

.role-hdr-txt {
    font-family:'Proxima Nova W05 Regular';
}

.port-role {
    font-family:'Proxima Nova W05 Light';
    font-size: 18px;
    float: left;
    clear: both;
    display: block;
    color: #444444;
}

.port-main-img {
    width: 100%;
    max-width: 945px;
    margin-top: 40px;
    margin-bottom: 35px;
    float: left;
	clear: both;
}

.port-header {
    font-family:'Proxima Nova W05 Regular';
    color: #656565;
    font-size: 22px;
    padding-bottom: 10px;
}

.portfolio-results-wrap .port-header {
    // font-family:'Proxima Nova W05 Medium';
}

.port-text {
    font-family:'Proxima Nova W05 Light';
    color: #656565;
    font-size: 18px;
    padding-bottom: 30px;
}

.results-img-wrp {
    height: $results-img-height;
    text-align: center;
	background-color: #fafafa;
}

.results-img-wrp:focus, .results-img-sizing-wrap:focus, .results-img-anchor:focus {
    outline: none;
}

.results-img-div {
    height: $results-img-height;
    width: 80%;
	margin: 0 10%;
}

.results-img-anchor {
    display: block;
    float: left;
	width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
}

.results-img-anchor img {
    @include animationNoFlicker;
}

.results-img-anchor:hover img {
    transform: scale(1.05);
}

.results-img-anchor:hover {
    border: 1px solid #eoeoeo; /* #dadada */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.results-img-anchor {
    transition: $scale-transition-buttons;
}

.results-img-sizing-wrap {
    width: 80%;
	height: 80%;
	margin: auto;
    margin-top: 16.5px; /* TO DO: Can make function of height */
    margin-bottom: 16.5px;
    display: flex;
    justify-content: center;
}

.results-img-sizing-wrap img {
    max-width: 100%;
    max-height: 100%;
    transition: $scale-transition-buttons;
    object-fit: contain;
}

.results-caption {
    font-family:'Proxima Nova W05 Light';
    font-size: 14px;
    color: #656565;
    float: left;
    padding-top: 8px;
    text-align: left;
}

/* FANCYBOX OVERWRITES */@at-root

.fancybox-bg {
    background-color: #ffffff !important;
}

.fancybox-button--play, .fancybox-button--thumbs {
    display: none !important;
}

.fancybox-caption {
    background-color: #484848;
}

.fancybox-caption:before {
    background: none !important;
}

body .fancybox-button {
    background-color: rgba(0, 0, 0, 0.65);
}


/* ------------------- 
ABOUT
---------------------- */

.about-paragraph {
    font-family:'Proxima Nova W05 Light';
    font-size: 22px;
    color: #323232;
    margin-bottom: 30px;
}

.bold-experience {
    font-family:'Proxima Nova W05 Regular';
    color: #1b1b1b;
}

.about-work-together-txt {
    font-family:'Proxima Nova W05 Regular';
    text-align: center;
    font-size: 26px;
    margin-top: 45px;
    margin-bottom: 20px;
    color: #4c4c4c;
}

.work-together-link-wrap {
    text-align: center;
}

.work-together-link {
    display: inline-block;
    margin-bottom: 40px;
    transition: $scale-transition-buttons;
    @include animationNoFlicker;
}

.work-together-link:hover {
    transform: scale(1.05);
}



/* ------------------- 
FOOTER
---------------------- */

.jl-footer-wrap {
    background-color: #26282e;
    color: white;
}

.footer-color-bar {
    display: block;
	height: 4px;
	background-image: linear-gradient(to right, #3ba0d0, #7d5ab1, #b76653, #1178cd);
}

.jl-footer-content-wrap {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

.jl-footer-wrap a {
    color: white;
}

.jl-footer-logo {
    display: block;
    margin: 30px 0 25px 0;
    opacity: 0.5;
    font-size: 28px;
}

.jl-footer-nav {
    margin-bottom: 30px;
    font-family: 'Source Sans Pro', sans-serif;
    opacity: .5;
}

.jl-footer-nav li {
    margin: 0 auto;
}

.jl-social-btn {
    display: inline-block;
    margin: 0 20px;
    margin-bottom: 35px;
    padding: 5px 7px;
    border: 1px solid rgba(255, 255, 255, 1);
    width: 110px;
    height: 45px;
    opacity: .40;
    transition: opacity $short-transition;
}

.jl-social-btn:hover {
    cursor: pointer;
    opacity: .7;
}

.jl-social-btn.linkedin {
    background: url("../images/social-linkedin.png") no-repeat center center;
    background-size: 65%;
}

.jl-social-btn.github {
    background: url("../images/social-github.png") no-repeat center center;
    background-size: 65%;
}

.footer-link {
    opacity: 0.9;
}

.footer-link:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s ease-in-out;
}

.footer-link.react {
    color: #3daadd;
}

.footer-link.foundation {
    color: #9775c5;
}

.footer-link.html5 {
    color: #e67c61;
}

.footer-link.css3 {
    color: #0e7fd9;
}


.jl-copyright {

}

.jl-site-built-with {
    margin-bottom: 10px;
}

.jl-copyright, .site-built-text {
    opacity: 0.4;
}

.jl-site-built-with a:hover {
    cursor: pointer;
}

// $breakpoints: (
//   small: 0,
//   medium: 640px,
//   large: 1024px,
//   xlarge: 1300px,
//   xxlarge: 1440px,
// );

// Use: breakpoints(name)

@media only screen and (max-width: breakpoints(xlarge)) {
    
    .featured-work-wrap {
        margin: 0 5%;
    }

  
   
}



@media only screen and (max-width: breakpoints(large)) {

    .featured-work-spacing-wrap {
        width: 270px;
    }

    .featured-wrk-text {
        width: 100%;
    }

    .featured-wrk-img {
        height: 190px;
        margin-top: 30px;
    }

}



@media only screen and (max-width: breakpoints(medium)) {

    .header-color-tint {
        opacity: 0.9;
    }

    .results-img-wrp {
        margin-bottom: 60px;
    }

    .splash-name {
        font-size: 30px;
    }
    .splash-sentence {
        font-size: 20px;
        padding: 0 10px;
    }

    .see-portfolio-btn {
        font-size: 16px;
    }

    .about-paragraph {
        font-size: 16px;
    }

    .about-work-together-txt {
        font-size: 20px;
    }


}





@media only screen and (min-width: breakpoints(large)) {

    .portfolio-details-wrap {
        margin-top: 30px;
        padding-left: 20px;
    }


}
